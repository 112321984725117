import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Home from './pages/Home/Home';
import Results from './pages/Results/Results';
import LeiPage from './pages/LeiPage/LeiPage';
import ContactPage from './pages/Contact/Contact';
import TermsOfService from './pages/Legal/Terms/Page';
import PrivacyPolicy from './pages/Legal/Privacy/Page';

// Import the CSS file for custom styles
import './index.css';

const App: React.FC = () => {
  return (
    <>
      <HelmetProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/results" element={<Results />} />
          <Route path="/lei/:lei" element={<LeiPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/legal/terms" element={<TermsOfService />} />
          <Route path="/legal/privacy" element={<PrivacyPolicy />} />
        </Routes>
      </HelmetProvider> 
    </>
  );
}

export default App;
