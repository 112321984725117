import { CompanyDetails } from "../../../pages/LeiPage/CompanyDetails";
import { Link } from "react-router-dom";

interface Tab1LeiRegistrationDetailsProps {
    companyDetails: CompanyDetails;
    managingLouName: any;
    validationAuthority: any;
}

const Tab1LeiRegistrationDetails: React.FC<Tab1LeiRegistrationDetailsProps> = ({ companyDetails, managingLouName, validationAuthority }) => {
    const { registration } = companyDetails; 

    return (
        <div>
          {/* LEI Registration Details */}
          <h3 className="whitespace-nowrap text-xl mt-4 mb-2 font-bold">LEI registration details</h3>
          <table>
            <tbody>
              <tr>
                <td className='whitespace-nowrap font-bold align-top pr-2'>LEI reg. status</td><td className='align-top' style={{ color: registration?.status === 'RETIRED' ? 'darkred' : registration?.status === 'MERGED' ? 'darkblue' : registration?.status === 'ISSUED' ? 'green' : 'red' }}>{registration?.status}</td>
              </tr>
              <tr>
                <td className='whitespace-nowrap align-top font-bold pr-2'>Initial LEI reg. date</td><td className='align-top'>{registration?.initialRegistrationDate?.slice(0,19).replace("T"," ") }</td>
              </tr>
              <tr>
                <td className='whitespace-nowrap align-top font-bold pr-2'>Last LEI update</td><td className='align-top'>{registration?.lastUpdateDate?.slice(0,19).replace("T"," ") }</td>
              </tr>
              <tr>
                <td className='whitespace-nowrap align-top font-bold pr-2'>Next LEI renewal</td><td className='align-top'>{registration?.nextRenewalDate?.slice(0,19).replace("T"," ") }</td>
              </tr>
              <tr>
                <td className='align-top font-bold pr-2'>LEI issuer (Managing LOU)</td><td className='align-top'><Link to={`/lei/${registration?.managingLou}`} title={managingLouName}>{managingLouName}<br />{registration?.managingLou}</Link></td>
              </tr>
              <tr>
                <td className='whitespace-nowrap font-bold align-top pr-2'>Corroboration level</td><td className='align-top'>{registration?.corroborationLevel}</td>
              </tr>
              <tr>
                <td className='whitespace-nowrap font-bold align-top pr-2'>Data validated at</td>
                <td className='align-top break-words'>
                  <div className='tooltip' data-tooltip='International Organization Name'>
                    {[
                      validationAuthority?.internationalName, 
                      validationAuthority?.internationalOrganizationName, 
                    ].filter(Boolean).join(', ')}
                  </div>
                  <div className='tooltip' data-tooltip='Local Organization Name'>
                    {[
                      validationAuthority?.localName, 
                      validationAuthority?.localOrganizationName
                    ].filter(Boolean).join(', ')}
                  </div>
                  <div className='tooltip' data-tooltip='Validation Authority Code'>
                    {registration?.validatedAt?.id}
                  </div>
                  {validationAuthority?.localName && (
                    <ul>
                      {validationAuthority?.jurisdictions.map((jurisdiction: any, index: number) => (
                        <li key={index}>
                        {jurisdiction.country && jurisdiction.country}&nbsp;
                        {jurisdiction.countryCode && `(${jurisdiction.countryCode})`}&nbsp;
                        {jurisdiction.jurisdiction && `- ${jurisdiction.jurisdiction}`}
                      </li>
                      ))}
                    </ul>
                  )}
                </td>
              </tr>
              {registration.validatedAs && (
                <tr>
                  <td className='whitespace-nowrap align-top font-bold pr-2'>Data validated as</td><td className='align-top'>{registration?.validatedAs}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>  
    );
};

export default Tab1LeiRegistrationDetails;