import React, { useEffect, useState } from 'react';
import { fetchCompanyData } from '../../../../utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import type { Tab2EE, EttevotjadItem, ArinimedItem } from './types';

const Tab2Ee: React.FC<{ companyNumber: string }> = ({ companyNumber }) => {
  const [companyData, setCompanyData] = useState<Tab2EE | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [hoveredField, setHoveredField] = useState<string | null>(null);
  const [copiedField, setCopiedField] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchCompanyData(companyNumber);
        const data: Tab2EE = JSON.parse(response);
        setCompanyData(data);
      } catch (error) {
        console.error('Error fetching company data:', error);
        setError('Failed to fetch company data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [companyNumber]);


  const handleCopy = (text: string, field: string) => {
    navigator.clipboard.writeText(text);
    setCopiedField(field);
    setTimeout(() => setCopiedField(null), 2000);
  };

  if (loading) {
    return <div className='text-center mt-8'>Requesting corporate registry...</div>;
  }

  if (error) {
    return <div className='text-center mt-8'>Error: {error}</div>;
  }

  if (!companyData) {
    return <div className='mt-8'>No data available</div>;
  }

  const companyInfo: EttevotjadItem | undefined = companyData.keha.ettevotjad.item[0];
  const companyName = companyInfo?.nimi || 'N/A';
  const regCode = companyInfo?.ariregistri_kood || 'N/A';
  const companyId = companyInfo?.ettevotja_id || 'N/A';
  const kmkr = companyInfo?.kmkr_number || 'N/A';
  const arinimed: ArinimedItem[] = companyInfo?.yldandmed?.arinimed?.item || [];
  const arinimedMain = arinimed.length > 1 ? arinimed.slice(0, -1) : [];

  return (
    <>
      <div className='w-full'>
        <h3 className='mt-8 text-2xl font-bold px-4'>{companyName}</h3>
        <div className='grid gap-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 px-4 py-4'>
          <div>
            <h3 className="mt-4 mb-2 text-xl font-bold">General Information</h3>
            <table>
              <tbody>
                <tr className='hover:bg-btn-background'>
                  <td className="whitespace-nowrap align-top pr-4 font-bold">Registry Code</td>
                  <td className="align-top pr-4">
                    <p
                      onMouseEnter={() => setHoveredField('regCode')}
                      onMouseLeave={() => setHoveredField(null)}
                    >
                      {regCode}
                      {hoveredField === 'regCode' && copiedField !== 'regCode' && (
                        <FontAwesomeIcon
                          icon={faCopy}
                          className='cursor-pointer ml-1'
                          onClick={() => handleCopy(String(regCode), 'regCode')}
                        />
                      )}
                      {copiedField === 'regCode' && <span className='rounded-md border bg-btn-background p-1 ml-1 text-sm'>Copied!</span>}
                    </p>
                  </td>
                </tr>
                <tr className='hover:bg-btn-background'>
                  <td className="whitespace-nowrap align-top pr-4 font-bold">VAT ID (KMKR)</td>
                  <td className="align-top pr-4">
                    <p
                      onMouseEnter={() => setHoveredField('kmkr')}
                      onMouseLeave={() => setHoveredField(null)}
                    >
                      {kmkr === '[object Object]' ? 'N/A' : kmkr}
                      {kmkr !== '[object Object]' && hoveredField === 'kmkr' && copiedField !== 'kmkr' && (
                        <FontAwesomeIcon
                          icon={faCopy}
                          className='cursor-pointer ml-1'
                          onClick={() => handleCopy(String(kmkr), 'kmkr')}
                        />
                      )}
                      {copiedField === 'kmkr' && <span className='rounded-md border bg-btn-background p-1 ml-1 text-sm'>Copied!</span>}
                    </p>
                  </td>
                </tr>
                <tr className='hover:bg-btn-background'>
                  <td className="whitespace-nowrap align-top pr-4 font-bold">Company Internal ID</td>
                  <td className="align-top pr-4">
                    <p
                      onMouseEnter={() => setHoveredField('companyId')}
                      onMouseLeave={() => setHoveredField(null)}
                    >
                      {companyId}
                      {hoveredField === 'companyId' && copiedField !== 'companyId' && (
                        <FontAwesomeIcon
                          icon={faCopy}
                          className='cursor-pointer ml-1'
                          onClick={() => handleCopy(String(companyId), 'companyId')}
                        />
                      )}
                      {copiedField === 'companyId' && <span className='rounded-md border bg-btn-background p-1 ml-1 text-sm'>Copied!</span>}
                    </p>
                  </td>
                </tr>
                <tr className='hover:bg-btn-background'>
                  <td className="whitespace-nowrap align-top pr-4 font-bold">Previous Name(-s)</td>
                  <td className='align-top pr-4'>
                    {arinimedMain.length > 0 ? (
                      <ul>
                        {arinimedMain.map((item: ArinimedItem, index: number) => (
                          <li key={index} className='mb-2'>
                            <p>{item?.sisu ?? 'N/A'}</p>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>N/A</p>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tab2Ee;