import React from 'react';
import { CompanyDetails } from '../../pages/LeiPage/CompanyDetails'; // Updated path to use the correct types file
import countries from 'i18n-iso-countries';
import { Link } from 'react-router-dom'; // Use React Router's Link if you need client-side routing

interface LeiFaqProps {
  companyDetails: CompanyDetails;
}

const LeiFaq: React.FC<LeiFaqProps> = ({ companyDetails }) => {
  const {
    lei,
    legalName,
    legalAddress,
    headquartersAddress,
    legalForm,
    creationDate,
  } = companyDetails;

  const legalAddressCountryName = countries.getName(legalAddress?.country as string, 'en');
  const headquartersAddressCountryName = countries.getName(headquartersAddress?.country as string, 'en');

  return (
    <div itemScope itemType="https://schema.org/FAQPage" className="px-4">
      <h2 className="mt-8 mb-2 text-2xl font-bold">FAQ</h2>
      <p className="font-bold">Frequently Asked Questions about {legalName.name}</p>

      {/* FAQ Item 1: Legal Address */}
      <details itemProp="mainEntity" itemScope itemType="https://schema.org/Question">
        <summary>
          <h3 itemProp="name">What is the legal address of {legalName.name}?</h3>
        </summary>
        <div itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
          <p itemProp="text">
            {legalName.name} has its legal address at {legalAddress?.addressLines.join(', ')}
            {legalAddress?.city && `, ${legalAddress.city}`}
            {legalAddress?.region && `, ${legalAddress.region}`}
            {legalAddress?.country && ` - ${legalAddressCountryName}`}
            {legalAddress?.postalCode && `, ${legalAddress.postalCode}`}
          </p>
        </div>
      </details>

      {/* FAQ Item 2: Headquarters Address */}
      <details itemProp="mainEntity" itemScope itemType="https://schema.org/Question">
        <summary>
          <h3 itemProp="name">Where is {legalName.name} headquartered?</h3>
        </summary>
        <div itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
          <p itemProp="text">
            {legalName.name} has its headquarters (HQ) address at {headquartersAddress?.addressLines.join(', ')}
            {headquartersAddress?.city && `, ${headquartersAddress.city}`}
            {headquartersAddress?.region && `, ${headquartersAddress.region}`}
            {headquartersAddress?.country && ` - ${headquartersAddressCountryName}`}
            {headquartersAddress?.postalCode && `, ${headquartersAddress.postalCode}`}
          </p>
        </div>
      </details>

      {/* FAQ Item 3: Legal Form */}
      {legalForm && legalForm.id && (
        <details itemProp="mainEntity" itemScope itemType="https://schema.org/Question">
          <summary>
            <h3 itemProp="name">What is {legalName.name}'s legal form?</h3>
          </summary>
          <div itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
            <p itemProp="text">
              {legalName.name} is registered as a {legalForm.id}.
            </p>
          </div>
        </details>
      )}

      {/* FAQ Item 4: Registration Date */}
      <details itemProp="mainEntity" itemScope itemType="https://schema.org/Question">
        <summary>
          <h3 itemProp="name">When was {legalName.name} registered?</h3>
        </summary>
        <div itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
          <p itemProp="text">{legalName.name} was registered on {creationDate?.slice(0, 10).replace('T', ' ')}.</p>
        </div>
      </details>

      {/* FAQ Item 5: Register an LEI */}
      <details itemProp="mainEntity" itemScope itemType="https://schema.org/Question">
        <summary>
          <h3 itemProp="name">How to register an LEI code in {legalAddress?.country && legalAddressCountryName}?</h3>
        </summary>
        <div itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
          <p itemProp="text">
            To register an LEI code for the entity from {legalAddress?.country && legalAddressCountryName}, please go to the{' '}
            <a
              href="https://app.leipapa.com/create"
              target="_blank"
              rel="noopener noreferrer"
              title="Register an LEI"
            >
              "Register an LEI code"
            </a>{' '}
            section of the LEIpapa app and enter the details of your entity. After the payment, you will get your LEI code shortly.
          </p>
        </div>
      </details>

      {/* FAQ Item 6: Transfer an LEI */}
      <details itemProp="mainEntity" itemScope itemType="https://schema.org/Question">
        <summary>
          <h3 itemProp="name">How to transfer my LEI code to LEIpapa?</h3>
        </summary>
        <div itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
          <p itemProp="text">
            To transfer your existing LEI code to LEIpapa, please go to the{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://app.leipapa.com/transfer"
              title="Transfer an LEI"
            >
              "Transfer an LEI code"
            </a>{' '}
            section of the LEIpapa app and enter the details of your entity. Also,{' '}
            <a
              href="https://leipapa.com/docs/transfer-an-lei/"
              title="Transfer an LEI"
              target="_blank"
              rel="noreferrer"
            >
              read the full information
            </a>{' '}
            on LEI transfer on the LEIpapa website.
          </p>
        </div>
      </details>

      {/* Best Practice Information */}
      <div className="px-8 py-6 text-justify mt-8 border border-l-4 bg-btn-background rounded-md">
        <strong>💡 Best Practice</strong>
        <p className="mt-4">
          Assign a unique{' '}
          <a
            href="https://leipapa.com/docs/what-is-an-lei-number/"
            target="_blank"
            rel="noreferrer"
            title="Get to know what is an LEI number"
          >
            LEI number
          </a>{' '}
          to each contact you have to identify and avoid transaction risks linked to contacts with similar names. This company{' '}
          <strong>{legalName.name}</strong> has an LEI number <strong>{lei}</strong>.
        </p>
      </div>

      {/* Contact Information */}
      <div className="text-center mt-4">
        <p>
          If {legalName.name} is your company and you would like to add any updates to the registry, please{' '}
          <Link to="/contact" title="Contact LEIprofile">contact us</Link>.
        </p>
      </div>
    </div>
  );
};

export default LeiFaq;