// src/utils/flagEmoji.ts
export const getFlagEmoji = (countryCode: string) => {
  if (!countryCode || typeof countryCode !== 'string') {
    return ''; // Return an empty string or a default value if countryCode is invalid
  }

  // Extract the first two characters of the country code
  const shortCountryCode = countryCode.slice(0, 2).toUpperCase();

  const codePoints = shortCountryCode
    .split('')
    .map(char => 127397 + char.charCodeAt(0));

  return String.fromCodePoint(...codePoints);
};