export default function MainButton() {
    return (
      <a
        className="text-foreground py-2 px-3 flex rounded-md no-underline hover:bg-btn-background-hover border"
        href="/"
        rel="noreferrer"
        title="LEIprofile Main Page"
      >
        <div className="w-full flex">
          <div className="color1 w-[5px]">&nbsp;</div>
          <div className="color2 w-[5px]">&nbsp;</div>
          <div className="color3 w-[5px]">&nbsp;</div>
          <div className="color4 w-[5px]">&nbsp;</div>
        </div>
        <strong className="hidden">&nbsp;LEIprofile</strong>
      </a>
    );
  }
  