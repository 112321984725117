import { Link } from "react-router-dom";

const currentYear = new Date().getFullYear();

const Footer = () => {
    return (
        <div>
            <div><Link className="font-bold hover:underline text-foreground" to='/' title="LEIprofile">LEIprofile</Link>. The Global Corporate Index.</div>
            <div className="mb-4">Make reliable decisions about who to do business with!</div>
            <div>
              <Link to='/legal/terms' title='Terms of Service'>Terms of Service</Link>&nbsp;|&nbsp;
              <Link to='/legal/privacy' title='Privacy Policy'>Privacy Policy</Link>&nbsp;|&nbsp;
              <Link to='/contact' title='Contact LEIprofile'>Contact</Link>
            </div>
            <div>
                Powered by{" "}
                <a
                    href="https://leipapa.com"
                    target="_blank"
                    className="font-bold hover:underline text-foreground"
                    rel="noreferrer"
                    title="Go to LEIpapa website"
                >
                    LEIpapa
                </a>.&nbsp;Made with ❤️ in <a className="text-foreground font-bold" href='https://leipapa.com/global/lei-estonia/' title='LEI availability in Estonia' target="_blank" rel='noreferrer noopener'>Estonia</a> 🇪🇪
            </div>
            <div>
                <p>&copy; {`2021 - ${currentYear}. All rights reserved.`}&nbsp;</p>
            </div>
        </div>
    );
}

export default Footer;
