import React from 'react';
import { Helmet } from 'react-helmet-async';

interface MetaProps {
  title?: string;
  description?: string;
  canonicalUrl?: string; 
  robotsContent?: string;
}

export default function Meta({ 
  title = 'LEIprofile', 
  description = 'The Global Corporate Index', 
  canonicalUrl, // Destructure canonicalUrl prop
  robotsContent = 'index, follow', 
}: MetaProps) {
  const currentUrl = window.location.href; // Get the current URL
  const finalCanonicalUrl = canonicalUrl || currentUrl; // Use canonicalUrl if provided, otherwise use current URL

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="robots" content={robotsContent} />
      <link rel="canonical" href={finalCanonicalUrl} /> {/* Set the canonical URL conditionally */}
    </Helmet>
  );
}