// components/tabs/tab1/GeneralInfoAndOtherNames.tsx

import { CompanyDetails } from '../../../pages/LeiPage/CompanyDetails';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

interface Tab1GeneralInformationProps {
  companyDetails: CompanyDetails;
  legalFormNames: any;
}

const Tab1GeneralInformation: React.FC<Tab1GeneralInformationProps> = ({ companyDetails, legalFormNames }) => {
  const { lei, registeredAs, creationDate, expiration, category, legalForm, otherNames } = companyDetails;
  const [hoveredField, setHoveredField] = useState<string | null>(null);
  const [copiedField, setCopiedField] = useState<string | null>(null);
  const handleCopy = (text: string, field: string) => {
    navigator.clipboard.writeText(text);
    setCopiedField(field);
    setTimeout(() => setCopiedField(null), 2000); // Remove "Copied!" message after 2 seconds
  };

  return (
    <>
      {/* General Information */}
      <div>
        <h3 className="mt-4 mb-2 text-xl font-bold">General Information</h3>
        <table>
          <tbody>
            <tr className='hover:bg-btn-background'>
              <td className='align-top pr-4 font-bold'>LEI code</td>
              <td>
                <p
                  onMouseEnter={() => setHoveredField('lei')}
                  onMouseLeave={() => setHoveredField(null)}
                >
                  {lei}
                  {hoveredField === 'lei' && copiedField !== 'lei' && (
                    <FontAwesomeIcon
                      icon={faCopy}
                      className='cursor-pointer ml-1'
                      onClick={() => handleCopy(lei, 'lei')}
                    />
                  )}
                  {copiedField === 'lei' && <span className='rounded-md border bg-btn-background p-1 ml-1 text-sm'>Copied!</span>}
                </p>
              </td>
            </tr>
            {registeredAs && registeredAs.length > 0 && (
              <tr className='hover:bg-btn-background'>
                <td className="align-top pr-4 font-bold">Reg. No.</td>
                <td>
                  <p
                    onMouseEnter={() => setHoveredField('registeredAs')}
                    onMouseLeave={() => setHoveredField(null)}
                  >
                    {registeredAs}
                    {hoveredField === 'registeredAs' && copiedField !== 'registeredAs' && (
                      <FontAwesomeIcon
                        icon={faCopy}
                        className='cursor-pointer ml-1'
                        onClick={() => handleCopy(registeredAs, 'registeredAs')}
                      />
                    )}
                    {copiedField === 'registeredAs' && <span className='rounded-md border bg-btn-background p-1 ml-1 text-sm'>Copied!</span>}
                  </p>
                </td>
              </tr>)}
            {creationDate && (<tr className='hover:bg-btn-background'><td className="align-top pr-4 font-bold">Registration Date</td><td>{creationDate.slice(0,10).replace("T"," ")}</td></tr>)}
            {expiration?.date && (<tr className='hover:bg-btn-background'><td className="align-top pr-4 font-bold">Expiration Date</td><td>{expiration.date?.slice(0,10).replace("T"," ")}</td></tr>)}
            {category && (<tr className='hover:bg-btn-background'><td className="align-top pr-4 font-bold">General category</td><td>{category}</td></tr>)}
            {legalForm && legalForm?.id.length > 0 && (
              <tr className='hover:bg-btn-background'>
                <td className="align-top pr-4 font-bold">Legal Form</td>
                <td className="align-top">
                  {legalForm.id}
                  {legalFormNames && (
                    <>
                      <ul>
                        {legalFormNames.map((name: any, index: number) => (
                          <li key={index}>{name.localName} {name.language && `(${name.language})`}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      
      {/* Other Names */}
      <div>
        {otherNames && otherNames.length > 0 && (
          <div>
            <h3 className="mt-4 mb-2 text-xl font-bold">Other Names</h3>
            <ul>
              {otherNames.map((name, index) => (
                <li className='hover:bg-btn-background' key={index}>{name.name} ({name.language}, {name.type})</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default Tab1GeneralInformation;
