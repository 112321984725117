// src/pages/LeiPage.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Tab1 from '../../components/Tabs/Tab1/Tab1';
import { CompanyDetails } from './CompanyDetails';
import countries from 'i18n-iso-countries';
import { getFlagEmoji } from '../../utils/flagEmoji';
import { fetchLeiData } from '../../utils/api';
import LeiFaq from '../../components/Faq/LeiFaq';
import pageSchema from '../../utils/pageSchema';
import Layout from '../../components/Layout/Layout';
import PageMeta from './PageMeta';
import Tab2Ee from '../../components/Tabs/Tab2/EE/Tab2EE';

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const LeiPage: React.FC = () => {
  const { lei } = useParams<{ lei: string }>();
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails | null>(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const canonicalUrl = `https://leiprofile.com/lei/${lei}`;

  useEffect(() => {
    const fetchData = async () => {
      if (!lei) return; // Ensure 'lei' is defined before making the API call

      try {
        const data = await fetchLeiData(lei);
        const attributes = data.data.attributes;
        const entity = attributes.entity;
        const meta = data.meta;
        const relationships = data.data.relationships;
        const links = data.data.links;

        const companyDetails: CompanyDetails = {
          goldenCopy: meta.goldenCopy,
          lei: attributes.lei,
          legalName: entity.legalName,
          otherNames: entity.otherNames,
          legalAddress: entity.legalAddress,
          headquartersAddress: entity.headquartersAddress,
          registeredAt: entity.registeredAt,
          registeredAs: entity.registeredAs,
          jurisdiction: entity.jurisdiction,
          category: entity.category,
          legalForm: entity.legalForm,
          associatedEntity: entity.associatedEntity,
          status: entity.status,
          expiration: entity.expiration,
          successorEntity: entity.successorEntity,
          successorEntities: entity.successorEntities,
          creationDate: entity.creationDate,
          subCategory: entity.subCategory,
          otherAddresses: entity.otherAddresses,
          eventGroups: entity.eventGroups,
          registration: attributes.registration,
          bic: attributes.bic,
          mic: attributes.mic,
          ocid: attributes.ocid,
          spglobal: attributes.spglobal,
          conformityFlag: attributes.conformityFlag,
          relationships: {
            managingLou: relationships['managing-lou'],
            leiIssuer: relationships['lei-issuer'],
            fieldModifications: relationships['field-modifications'],
            directParent: relationships['direct-parent'],
            ultimateParent: relationships['ultimate-parent'],
            isins: relationships['isins'],
          },
          links: links,
        };

        setCompanyDetails(companyDetails);
      } catch (error) {
        console.error('Error fetching LEI data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [lei]);

  const handleTabChange = (index: number) => setTabIndex(index);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-center">Loading LEI data...</div>
      </div>
    );
  }

  if (!companyDetails) {
    return (
        <div className="flex justify-center items-center min-h-screen">
          <div className="text-center">No details for this query! Check your data!</div>
        </div>
      );
  }

  const { legalAddress, legalName, jurisdiction } = companyDetails;
  const countryName = countries.getName(jurisdiction, "en");
  const flagEmoji = getFlagEmoji(jurisdiction);

  // Prepare metadata for the page
  const title = `${legalName.name} (${companyDetails.registeredAs}), ${countryName}, LEI ${lei}`;
  const description = `${legalName.name} (${companyDetails.registeredAs}) from ${countryName} ${flagEmoji} LEI ${lei}. Review the full and updated company information at LEIprofile.`;
  const schema = pageSchema(canonicalUrl, legalName, countryName as string, lei!, companyDetails);

  return (
    <>
      <PageMeta
        legalName={legalName}
        countryName={countryName ?? ''}
        lei={lei ?? ''}
        companyDetails={companyDetails ?? { registeredAs: '' }}
        flagEmoji={flagEmoji ?? ''}
        canonicalUrl={canonicalUrl}
      />
      <Layout title={title} description={description} canonicalUrl={canonicalUrl}>
        {/* schema */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
        />
        <div className="mb-8 mx-auto max-w-4xl">
          <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 mt-4 mx-4 mb-4">
            <div>
              <h1 className="mt-6 text-4xl font-extrabold leading-none tracking-tight md:text-5xl lg:text-6xl">{legalName.name}</h1>
              <p className="mb-4">Complete information about the company</p>
              <p className="text-lg font-normal text-foreground lg:text-xl">
                <span className="font-bold">Reg. No: </span>
                {companyDetails.registeredAs || 'N/A'}
              </p>
              <p className="text-lg font-normal text-foreground lg:text-xl"><span className="font-bold">LEI: </span>{companyDetails.lei}</p>
              <p className="mb-6 text-lg font-normal text-foreground lg:text-xl">
                <span className="font-bold">Jurisdiction: </span>{jurisdiction} | {countryName} {flagEmoji}
              </p>
            </div>
            <div>
              <div className="flex lg:justify-end md:justify-end sm:justify-start">
                <table className="lg:mt-8 md:mt-8 sm:mt-0">
                  <tbody>
                    <tr>
                      <td className="text-center tooltip" data-tooltip="Grab this QR code and place it to your business cards, office door or other corporate media to show partners your verified details">
                        <img src={`https://api.qrserver.com/v1/create-qr-code/?data=https://leiprofile.com/lei/${companyDetails.lei}&size=180x180`} alt={`QR code of ${legalName.name}, LEI ${companyDetails.lei} at LEIpapa`} title={`QR code of ${legalName.name}, LEI ${companyDetails.lei} at LEIprofile`} />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center mt-2">
                        <a className="mr-2" href={`https://api.qrserver.com/v1/create-qr-code/?data=https://leiprofile.com/lei/${companyDetails.lei}&size=600x600&qzone=1`} target="_blank" rel="noreferrer" title={`Download QR code of ${legalName.name} in PNG`}>PNG</a>
                        <a href={`https://api.qrserver.com/v1/create-qr-code/?data=https://leiprofile.com/lei/${companyDetails.lei}&size=600x600&format=SVG`} target="_blank" rel="noreferrer" title={`Download QR code of ${legalName.name} in SVG`}>SVG</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="flex overflow-x-auto">
            <button className={`tab ${tabIndex === 0 ? 'active' : ''}`} onClick={() => handleTabChange(0)}>
              <h2 title='Legal Entity Data from Global LEI Index'>LEI Information</h2>
            </button>
            {legalAddress?.country === 'EE' && (
              <button className={`tab ${tabIndex === 1 ? 'active' : ''}`} onClick={() => handleTabChange(1)}>
                <h2 title='Company Data from e-Business Register'>Corporate Data</h2>
              </button>
            )}
          </div>
          
          <div className="tab-content">
            {tabIndex === 0 && <Tab1 companyDetails={companyDetails} />}
            {tabIndex === 1 && legalAddress?.country === 'EE' && <Tab2Ee companyNumber={companyDetails.registeredAs} />}
          </div>

          {companyDetails.lei && <LeiFaq companyDetails={companyDetails} />}
        </div>

        {/* Styles for tabs (regular CSS or inline styles instead of JSX styles) */}
        <style>
          {`
            .tab {
            padding: 10px 20px;
            cursor: pointer;
            border: none;
            background: none;
            font-size: 18px;
          }
          .tab.active {
            border-bottom: 2px solid #1976D2;
            font-weight: bold;
            color: #1976D2;
          }
        `}</style>
      </Layout>
    </>
  );
};

export default LeiPage;