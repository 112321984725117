// src/pages/Home.tsx
import React from 'react';
import Layout from '../../components/Layout/Layout';
import SearchBar from '../../components/SearchBars/_SearchBar';
import PageMeta from './PageMeta';

const Home: React.FC = () => {
  const title = "LEIprofile | LEI data corporate registry, LEI search lookup";
  const keywords = "LEI, LEI code, Corporate data, Company information, Company data, Company registry, Global LEI index, LEI search, LEI lookup."
  const description = "LEIprofile is the most advanced corporate registry database built on top of the Global LEI Index. Search company info here. LEI lookup.";
  return (
    <>
        <PageMeta title={title} description={description} keywords={keywords} />
        <Layout title={title} description={description}>
        {/* Center content vertically and horizontally */}
        <div className="flex flex-col justify-center items-center flex-grow mt-8">
            <div className="w-full max-w-xl flex-col">
            <h1 className="sr-only">LEIprofile</h1>
            <div className="flex flex-row items-center justify-center mt-8 mb-8">
                <div className="flex flex-row">
                <div className="px-[2px] py-[2px] color1">&nbsp;</div>
                <div className="px-[2px] py-[2px] color2">&nbsp;</div>
                <div className="px-[2px] py-[2px] color3">&nbsp;</div>
                <div className="px-[2px] py-[2px] color4">&nbsp;</div>
                </div>
                <p className="font-bold text-3xl lg:text-4xl !leading-tight mx-2" title="LEIprofile - LEI data corporate registry">
                LEIprofile
                </p>
            </div>
            <SearchBar />
            </div>
        </div>
        </Layout>
    </>
  );
};

export default Home;