import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFlagEmoji } from '../../utils/flagEmoji';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { fetchLeiRecordDetails, fetchLeiRecordByLei, fetchLeiRecordByName, fetchFuzzyCompletions, fetchAutoCompletions } from '../../utils/api';
import './SearchBar.css';

interface Suggestion {
  lei: string;
  entity: string;
  country: string;
}

const SearchBar: React.FC = () => {
  const [query, setQuery] = useState<string>('');
  const [autoCompletions, setAutoCompletions] = useState<Suggestion[]>([]);
  const [fuzzyCompletions, setFuzzyCompletions] = useState<Suggestion[]>([]);
  const navigate = useNavigate();
  const wrapperRef = useRef<HTMLFormElement>(null);

  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  useEffect(() => {
    setInitialLoading(false);
  }, []);

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (query.length < 3) {
      alert('Please enter at least 3 characters.');
      return;
    }
    navigate(`/results?query=${query}`);
  };

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuery(value);

    if (value.length >= 3) {
      try {
        console.log('Fetching autocompletions...');
        const autoCompletionResponse = await fetchAutoCompletions(value);
        console.log('Autocompletions:', autoCompletionResponse);

        // Fetch autocompletions
        const autoCompletionRecords = await Promise.all(
          autoCompletionResponse.data.map(async (item: any) => {
            const lei = item.relationships?.['lei-records']?.data?.id || '';
            const entity = item.attributes.value;

            if (lei) {
              const leiRecordUrl = item.relationships['lei-records'].links.related;
              const leiDetails = await fetchLeiRecordDetails(leiRecordUrl);
              return {
                lei,
                entity: leiDetails.entity || entity,
                country: leiDetails.country,
              };
            }

            return {
              lei,
              entity,
              country: '',
            };
          })
        );

        // Filter out records with empty LEI and deduplicate records
        const uniqueAutoCompletionRecords = Array.from(new Map(autoCompletionRecords.filter(record => record.lei).map(record => [record.lei, record])).values());
        setAutoCompletions(uniqueAutoCompletionRecords);

        // Fetch fuzzy completions
        const fuzzyCompletionResponse = await fetchFuzzyCompletions(value);
        const records = await Promise.all(
          fuzzyCompletionResponse.data.map(async (item: any) => {
            const lei = item.relationships?.['lei-records']?.data?.id || '';
            const entity = item.attributes.value;

            if (lei) {
              const leiRecordUrl = item.relationships['lei-records'].links.related;
              const leiDetails = await fetchLeiRecordDetails(leiRecordUrl);
              return {
                lei,
                entity: leiDetails.entity || entity,
                country: leiDetails.country,
              };
            }

            return {
              lei,
              entity,
              country: '',
            };
          })
        );

        // Filter out records with empty LEI and deduplicate records
        let uniqueFuzzyRecords = Array.from(new Map(records.filter(record => record.lei).map(record => [record.lei, record])).values());

        if (uniqueFuzzyRecords.length === 0) {
          // filter by LEI if fuzzy has no results
          const leiResponse = await fetchLeiRecordByLei(value);

          if (leiResponse.data.length > 0) {
            uniqueFuzzyRecords = leiResponse.data.map((record: any) => ({
              lei: record.attributes.lei,
              entity: record.attributes.entity.legalName.name,
              country: record.attributes.entity.legalAddress.country,
            }));
          } else {
            // filter by Name if both fuzzy and by LEI have no results
            const nameResponse = await fetchLeiRecordByName(value);

            uniqueFuzzyRecords = nameResponse.data.map((record: any) => ({
              lei: record.attributes.lei,
              entity: record.attributes.entity.legalName.name,
              country: record.attributes.entity.legalAddress.country,
            }));
          }
        }

        setFuzzyCompletions(uniqueFuzzyRecords);
      } catch (error) {
        console.error('Error in handleInputChange:', error);
        setAutoCompletions([]);
        setFuzzyCompletions([]);
      }
    } else {
      setAutoCompletions([]);
      setFuzzyCompletions([]);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setAutoCompletions([]);
      setFuzzyCompletions([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
    <form onSubmit={handleSearch} className="flex flex-col items-center w-full py-0 px-4" ref={wrapperRef}>
      {initialLoading ? (
      <div>Get ready for Search 🤓</div>
    ) : (
      <div className="bar">
        <input
          type="text"
          className="text-foreground w-full h-12 border-0 outline-none bg-background"
          placeholder="LEI code or company name..."
          value={query}
          onChange={handleInputChange}
        />
        <button id="search" title="Search" type="submit" className="search-icon">
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </div>
    )}

        {(autoCompletions.length > 0 || fuzzyCompletions.length > 0) && (
          <ul className="suggestions">
            {autoCompletions.map((suggestion, index) => (
              <li className="flex py-2 px-6 cursor-pointer rounded-md hover:bg-btn-background-hover" key={index} onClick={() => navigate(`/lei/${suggestion.lei}`)}>
                <div className="suggestion-item">
                  <div className="flex items-center w-8 ">
                    {suggestion.country && <span>{getFlagEmoji(suggestion.country)}</span>}
                  </div>
                  <div className="flex flex-col">
                    <div className="font-bold">
                      {suggestion.entity} | {suggestion.country}
                    </div>
                    <div className="text-foreground/85">({suggestion.lei})</div>
                  </div>
                </div>
              </li>
            ))}
            {fuzzyCompletions.length > 0 && (
              <li className="flex py-1 px-6 rounded-md">
                <div className="suggestion-item">
                  <div className="flex flex-col">
                    <div className="font-bold">
                      or did you mean...
                    </div>
                  </div>
                </div>
              </li>
            )}
            {fuzzyCompletions.map((suggestion, index) => (
              <li className="flex py-2 px-6 cursor-pointer rounded-md hover:bg-btn-background-hover" key={index} onClick={() => navigate(`/lei/${suggestion.lei}`)}>
                <div className="suggestion-item">
                  <div className="flex items-center w-8 ">
                    {suggestion.country && <span>{getFlagEmoji(suggestion.country)}</span>}
                  </div>
                  <div className="flex flex-col">
                    <div className="font-bold">
                      {suggestion.entity} | {suggestion.country}
                    </div>
                    <div className="text-foreground/85">({suggestion.lei})</div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}

        <div className="mt-8 mb-8 text-center">
          <button id="search" title="Search" type="submit" className="py-2 px-6 rounded-md no-underline hover:bg-btn-background-hover border disabled:cursor-not-allowed disabled:opacity-60">Search</button>
        </div>
      </form>
    </div>
  );
};

export default SearchBar;