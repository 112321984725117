import React from 'react';
import { Helmet } from 'react-helmet-async';

interface PageMetaProps {
  title: string;
  description: string;
  keywords: string;
  canonicalUrl?: string;
}

const PageMeta: React.FC<PageMetaProps> = ({ title, keywords, description, canonicalUrl }) => {
  const currentUrl = window.location.href; // Get the current URL
  const finalCanonicalUrl = canonicalUrl || currentUrl; // Use canonicalUrl if provided, otherwise use current URL
  return (
    <Helmet>

      <meta name="keywords" content={keywords} />

      {/* OpenGraph */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={finalCanonicalUrl} />
      <meta property="og:image:alt" content={title} />
      <meta property="og:image" content="/opengraph-image.png" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@leipapa_com" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content="/twitter-image.png" />
    </Helmet>
  );
};

export default PageMeta;
