import React, { useEffect, useState, useCallback } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { datasetSchema } from './Schema';
import { fetchLeiRecordDetails, fetchFuzzyCompletions, fetchAutoCompletions } from '../../utils/api';
import Layout from '../../components/Layout/Layout';

// Define the type for result items
interface ResultItem {
  lei: string;
  entity: string;
  companyNumber: string;
  country: string;
  leiStatus: string;
  entityStatus: string;
  category: string;
  conformityFlag: string;
}

const ResultsPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const initialQuery = searchParams.get('query') || '';
  const [query, setQuery] = useState<string>(initialQuery);
  const [autoCompletions, setAutoCompletions] = useState<ResultItem[]>([]);
  const [fuzzyCompletions, setFuzzyCompletions] = useState<ResultItem[]>([]);
  const [sortedResults, setSortedResults] = useState<ResultItem[]>([]);
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'ascending' | 'descending' }>({ key: '', direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const canonicalUrl = `https://leiprofile.com/${window.location.pathname}`;
  const resultsPerPage = 15;

  const fetchResults = useCallback(async (searchQuery: string) => {
    setLoading(true);
    try {
      // Fetch autocompletions
      const autoCompletionResponse = await fetchAutoCompletions(searchQuery);
      const autoCompletionRecords = await Promise.all(
        autoCompletionResponse.data.map(async (item: any) => {
          const lei = item.relationships?.['lei-records']?.data?.id || item.id || '';
          const entity = item.attributes.value || item.attributes.lei || '';

          if (lei) {
            const leiRecordUrl = item.relationships['lei-records'].links.related;
            const leiDetails = await fetchLeiRecordDetails(leiRecordUrl);

            return {
              lei,
              entity: entity,
              companyNumber: leiDetails.companyNumber,
              country: leiDetails.country,
              leiStatus: leiDetails.leiStatus,
              entityStatus: leiDetails.entityStatus,
              category: leiDetails.category,
              conformityFlag: leiDetails.conformityFlag,
            } as ResultItem;
          }

          return null;
        })
      );

      const autoCompletionFilteredRecords = autoCompletionRecords.filter((record): record is ResultItem => record !== null);
      setAutoCompletions(autoCompletionFilteredRecords);

      // Fetch fuzzy completions
      const fuzzyCompletionResponse = await fetchFuzzyCompletions(searchQuery);
      const fuzzyCompletionRecords = await Promise.all(
        fuzzyCompletionResponse.data.map(async (item: any) => {
          const lei = item.relationships?.['lei-records']?.data?.id || item.id || '';
          const entity = item.attributes.value || item.attributes.lei || '';

          if (lei) {
            const leiRecordUrl = item.relationships['lei-records'].links.related;
            const leiDetails = await fetchLeiRecordDetails(leiRecordUrl);

            return {
              lei,
              entity: entity,
              companyNumber: leiDetails.companyNumber,
              country: leiDetails.country,
              leiStatus: leiDetails.leiStatus,
              entityStatus: leiDetails.entityStatus,
              category: leiDetails.category,
              conformityFlag: leiDetails.conformityFlag,
            } as ResultItem;
          }

          return null;
        })
      );

      const fuzzyCompletionFilteredRecords = fuzzyCompletionRecords.filter((record): record is ResultItem => record !== null);
      setFuzzyCompletions(fuzzyCompletionFilteredRecords);

      setSortedResults([...autoCompletionFilteredRecords, ...fuzzyCompletionFilteredRecords]);
      setCurrentPage(1); // Reset to first page on new search
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
    setLoading(false);
  }, []);

  const countryCodeToFlagEmoji = useCallback((countryCode: string) => {
    return countryCode
      .toUpperCase()
      .replace(/./g, char => String.fromCodePoint(127397 + char.charCodeAt(0)));
  }, []);

  const sortResults = useCallback((key: keyof ResultItem) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    const sorted = [...sortedResults].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setSortedResults(sorted);
    setSortConfig({ key, direction });
  }, [sortedResults, sortConfig]);

  const getSortIndicator = useCallback((key: string) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return ' ';
  }, [sortConfig]);

  useEffect(() => {
    if (initialQuery) {
      fetchResults(initialQuery);
    }
  }, [initialQuery, fetchResults]);

  useEffect(() => {
    if (query.length >= 3) {
      fetchResults(query);
    }
  }, [query, fetchResults]);

  // Pagination controls
  const totalPages = Math.ceil(sortedResults.length / resultsPerPage);

  const handlePageChange = useCallback((pageNumber: number) => {
    setCurrentPage(pageNumber);
  }, []);

  const displayedResults = sortedResults.slice(
    (currentPage - 1) * resultsPerPage,
    currentPage * resultsPerPage
  );

  const metaTitle = `Search Results for "${query}" | LEIprofile`;
  const metaKeywords = `${query}, LEI, company search, LEI code search, LEI lookup, company information`;
  const metaDescription = `Search results for "${query}". LEIprofile is the most advanced corporate register built on top of the Global LEI System GLEIS.`;

  return (
    <>
        <Layout title={metaTitle} description={metaDescription}>
            <Helmet>
                <meta name="keywords" content={metaKeywords} />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={canonicalUrl} />
                <meta property="og:title" content={metaTitle} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={canonicalUrl} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@leipapa_com" />
                <meta name="twitter:title" content={metaTitle} />
                <meta name="twitter:description" content={metaDescription} />
                <script type="application/ld+json">
                {JSON.stringify(datasetSchema)}
                </script>
            </Helmet>

        <div className="mt-8 mb-8 w-full max-w-4xl px-4 mx-auto">
            <h1 className="font-bold text-4xl !leading-tight mt-4">Search Results for &quot;{query}&quot;</h1>
            <div className='text-xs mt-4 mb-4'>
                <p>Search for an LEI record within the whole LEI database.</p>
                <p>Search can be performed by Legal Entity, LEI code, and several other values.</p>
                <p>LEI codes that have "LAPSED" status for more than 1 year may not be visible in the search.</p>
            </div>
            <div className="w-full">
            <input
                type="text"
                className="search-input"
                placeholder="Search again..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
            />
            </div>
            {loading ? (
            <p className="mt-4 ml-2">Loading...</p>
            ) : displayedResults.length > 0 ? (
            <>
                <div className="table-container">
                <table className="results-table">
                    <thead>
                    <tr>
                        <th onClick={() => sortResults('entity')}>
                        Company Name {getSortIndicator('entity')}
                        </th>
                        <th onClick={() => sortResults('companyNumber')}>
                        Company Number {getSortIndicator('companyNumber')}
                        </th>
                        <th onClick={() => sortResults('lei')}>
                        LEI {getSortIndicator('lei')}
                        </th>
                        <th onClick={() => sortResults('country')}>
                        <div style={{ textAlign: 'center' }}>
                            Country {getSortIndicator('country')}
                        </div>
                        </th>
                        <th onClick={() => sortResults('category')}>
                        Category {getSortIndicator('category')}
                        </th>
                        <th onClick={() => sortResults('conformityFlag')}>
                        <div style={{ textAlign: 'center' }}>
                            Conformity {getSortIndicator('conformityFlag')}
                        </div>
                        </th>
                        <th onClick={() => sortResults('entityStatus')}>
                        Entity Status {getSortIndicator('entityStatus')}
                        </th>
                        <th onClick={() => sortResults('leiStatus')}>
                        LEI Status {getSortIndicator('leiStatus')}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {displayedResults.map((result, index) => (
                        <React.Fragment key={index}>
                        <tr>
                            <td>
                            <Link to={`/lei/${result.lei}`} title={`Go to the page of legal entity ${result.entity}`}>
                                {result.entity}
                            </Link>
                            </td>
                            <td>
                            <span title={`Legal entity ${result.entity} has a registration number ${result.companyNumber}`}>
                                {result.companyNumber}
                            </span>
                            </td>
                            <td>
                            <Link to={`/lei/${result.lei}`} title={`Go to the page of legal entity ${result.entity} with LEI ${result.lei}`}>
                                {result.lei}
                            </Link>
                            </td>
                            <td>
                            <div style={{ textAlign: 'center' }}>
                                {result.country && (
                                <>
                                    {countryCodeToFlagEmoji(result.country)} {result.country}
                                </>
                                )}
                            </div>
                            </td>
                            <td>{result.category}</td>
                            <td>
                            <div style={{ textAlign: 'center' }}>
                                {result.conformityFlag === 'CONFORMING' ? '🟢' : '🔴'}
                            </div>
                            </td>
                            <td>{result.entityStatus}</td>
                            <td style={{ color: result.leiStatus === 'RETIRED' ? 'darkred' : result.leiStatus === 'MERGED' ? 'darkblue' : result.leiStatus === 'ISSUED' ? 'green' : 'red' }}>
                            {result.leiStatus}
                            </td>
                        </tr>
                        {index === autoCompletions.length - 1 && fuzzyCompletions.length > 0 && (
                            <tr key="separator">
                            <td colSpan={8} className='italic bg-btn-background'>
                                or did you mean...
                            </td>
                            </tr>
                        )}
                        </React.Fragment>
                    ))}
                    </tbody>
                </table>
                </div>
                <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                    key={index}
                    className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
                    onClick={() => handlePageChange(index + 1)}
                    >
                    {index + 1}
                    </button>
                ))}
                </div>
            </>
            ) : (
            <p>No results found</p>
            )}
        </div>
        <style>{`
            .results-page {
                padding: 20px;
                font-size: 1rem;
            }
            .search-input {
                width: 100%;
                padding: 10px 20px;
                font-size: 1rem;
                border: 1px solid #ccc;
                border-radius: 15px;
                box-sizing: border-box;
            }
            .table-container {
                overflow-x: auto;
            }
            .results-table {
                width: 100%;
                border-collapse: collapse;
                margin-top: 20px;
                font-size: 0.9rem;
            }
            .results-table th,
            .results-table td {
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                padding: 8px;
                text-align: left;
            }
            .results-table th {
                background-color: #cfe2f3;
            }
            .results-table tr:hover {
                background-color: #f4f4f4;
            }
            .pagination {
                margin-top: 20px;
                display: flex;
                justify-content: center;
            }
            .page-button {
                border: 1px solid #ccc;
                color: #707070;
                padding: 0.1rem 0.2rem;
                margin: 0 5px;
                border-radius: 5px;
                outline: none;
                font-size: 0.9rem;
                width: 1.6rem;
            }
            .page-button.active {
                background-color: hsla(var(--btn-background-hover));
                // color: white;
            }
            .page-button:hover {
                background-color: hsla(var(--btn-background));
            }
            @media (max-width: 600px) {
                .results-page {
                padding: 10px;
                }
                .results-table th,
                .results-table td {
                padding: 6px;
                }
            }
            `}</style>
        </Layout>
    </>
  );
};

export default ResultsPage;