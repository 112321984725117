// src/utils/pageSchema.ts
const pageSchema = (
    canonicalUrl: string,
    legalName: { name: string },
    countryName: string,
    lei: string,
    companyDetails: any
  ) => {
    return {
      "@context": "https://schema.org",
      "@type": "Article",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": canonicalUrl,
      },
      headline: `${legalName.name} (${companyDetails.registeredAs}), ${countryName}, LEI ${lei}`,
      image: "/android-chrome-512x512.png",
      author: {
        "@type": "Organization",
        name: "LEIpapa",
        url: "https://leipapa.com",
      },
      publisher: {
        "@type": "Organization",
        name: "LEIprofile",
        logo: {
          "@type": "ImageObject",
          url: "/android-chrome-512x512.png",
        },
      },
      datePublished: companyDetails?.registration?.initialRegistrationDate?.slice(0, 10).replace("T", " "),
      dateModified: companyDetails?.registration?.lastUpdateDate?.slice(0, 10).replace("T", " "),
    };
  };
  
  export default pageSchema;