// src/pages/Results/Schema.ts

export const datasetSchema = {
    "@context": "https://schema.org/",
    "@type": "Dataset",
    "name": "LEI Search Results Database at LEIprofile",
    "description": "LEIprofile Database of Legal Entity Identifier (LEI) search results, including company information, status, and categories.",
    "url": "https://leiprofile.com/results",
    "keywords": [
      "LEI",
      "Legal Entity Identifier",
      "Company Search",
      "LEI Lookup",
      "Corporate Information",
      "LEI profile",
      "LEI code search"
    ],
    "sameAs": "https://search.gleif.org",
    "license" : "https://creativecommons.org/publicdomain/zero/1.0/",
    "isAccessibleForFree" : true,
    "hasPart" : [
        {
          "@type": "Dataset",
          "name": "Company Name",
          "description": "Legal Entity Name, Company Name",
          "license" : "https://creativecommons.org/publicdomain/zero/1.0/",
          "creator":{
             "@type":"Organization",
             "name": "LEIprofile"
          }
        },
        {
            "@type": "Dataset",
            "name": "Company Number",
            "description": "Legal Entity Registration Number, Company Registration Code",
            "license" : "https://creativecommons.org/publicdomain/zero/1.0/",
            "creator":{
               "@type":"Organization",
               "name": "LEIprofile"
            }
        },
        {
            "@type": "Dataset",
            "name": "LEI number",
            "description": "LEI number of the company",
            "license" : "https://creativecommons.org/publicdomain/zero/1.0/",
            "creator":{
               "@type":"Organization",
               "name": "LEIprofile"
            }
        },
        {
            "@type": "Dataset",
            "name": "Company Country (Jurisdiction)",
            "description": "Country or Jurisdiction where the legal entity is registered",
            "license" : "https://creativecommons.org/publicdomain/zero/1.0/",
            "creator":{
               "@type":"Organization",
               "name": "LEIprofile"
            }
        },
        {
            "@type": "Dataset",
            "name": "Category",
            "description": "Category of the legal entity",
            "license" : "https://creativecommons.org/publicdomain/zero/1.0/",
            "creator":{
               "@type":"Organization",
               "name": "LEIprofile"
            }
        },
        {
            "@type": "Dataset",
            "name": "Conformity",
            "description": "The indicator of the legal entity is conforming with the ROC policies",
            "license" : "https://creativecommons.org/publicdomain/zero/1.0/",
            "creator":{
               "@type":"Organization",
               "name": "LEIprofile"
            }
        },
        {
            "@type": "Dataset",
            "name": "Company Status",
            "description": "The official status of the legal entity (company)",
            "license" : "https://creativecommons.org/publicdomain/zero/1.0/",
            "creator":{
               "@type":"Organization",
               "name": "LEIprofile"
            }
        },
        {
            "@type": "Dataset",
            "name": "LEI status",
            "description": "The status of the LEI code of the company",
            "license" : "https://creativecommons.org/publicdomain/zero/1.0/",
            "creator":{
               "@type":"Organization",
               "name": "LEIprofile"
            }
        }
      ],
      "creator":{
         "@type":"Organization",
         "url": "https://leiprofile.com",
         "name":"LEIprofile by LEIpapa",
         "contactPoint":{
            "@type":"ContactPoint",
            "contactType": "customer service",
            "telephone":"+372-613-8888",
            "email":"info@leiprofile.com"
         }
      },
      "funder":{
         "@type": "Organization",
         "sameAs": "https://leipapa.com",
         "name": "LEIpapa OÜ"
      },
      "includedInDataCatalog":{
        "@type":"DataCatalog",
        "name":"gleif.org"
     },
  };