import { CompanyDetails } from "../../../pages/LeiPage/CompanyDetails";

interface Tab1ParentsProps {
    companyDetails: CompanyDetails;
    directParent: any;
    ultimateParent: any;
}

const Tab1Parents: React.FC<Tab1ParentsProps> = ({ companyDetails, directParent, ultimateParent }) => {
    const { legalName  } = companyDetails; 

    return (
        <>
            {/* Direct Parent */}
            <div>
                <h3 className="mt-4 mb-2 text-xl font-bold">Direct Parent <span className='text-sm tooltip' data-tooltip="Information on direct parent of this legal entity based on accounting consolidation.">ℹ️</span></h3>
                {directParent ? (
                    <p>
                    <a href={`/lei/${directParent.attributes?.lei}`}>{directParent.attributes?.entity?.legalName?.name}</a>
                    <span className='tooltip' data-tooltip='The "child" entity has its accounts fully consolidated by the "parent" entity, in the sense given by the accounting standard(s) specified.'> ℹ️</span>
                    </p>
                ) : (
                    <p>No direct parent data available for {legalName.name}</p>
                )}
            </div>

            {/* Ultimate Parent */}
            <div>
                <h3 className="mt-4 mb-2 text-xl font-bold">Ultimate Parent <span className='tooltip text-sm' data-tooltip="Information on ultimate parent of this legal entity based on accounting consolidation.">ℹ️</span></h3>
                {ultimateParent ? (
                    <p>
                    <a href={`/lei/${ultimateParent.attributes?.lei}`}>{ultimateParent.attributes?.entity?.legalName?.name}</a>
                    <span className='tooltip' data-tooltip='The "child" entity is ultimately consolidated by the "parent" entity, in the sense given by the accounting standard(s) specified.'> ℹ️</span>
                    </p>
                ) : (
                    <p>No ultimate parent data available for {legalName.name}</p>
                )}
            </div>
        </>
    );
};

export default Tab1Parents;