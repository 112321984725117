// components/tabs/tab1/Tab1.tsx

import React, { useEffect, useState } from 'react';
import { CompanyDetails } from '../../../pages/LeiPage/CompanyDetails';
import { fetchManagingLou, fetchRegistrationAuthority, fetchValidationAuthority, fetchEntityLegalForm, fetchLeiData, fetchIsins } from '../../../utils/api';
import countries from 'i18n-iso-countries';
import { getFlagEmoji } from '../../../utils/flagEmoji';
import Tab1GeneralInformation from './Tab1GeneralInformation';
import Tab1Addresses from './Tab1Addresses';
import Tab1LeiRegistrationDetails from './Tab1LeiRegistrationDetails';
import Tab1OtherInformation from './Tab1OtherInformation';
import Tab1Parents from './Tab1Parents';

interface Tab1Props {
  companyDetails: CompanyDetails;
}

const Tab1: React.FC<Tab1Props> = ({ companyDetails }) => {
  const [registrationAuthority, setRegistrationAuthority] = useState<any>(null);
  const [validationAuthority, setValidationAuthority] = useState<any>(null);
  const [legalFormNames, setLegalFormNames] = useState<any>(null);
  const [managingLouName, setManagingLouName] = useState<string | null>(null);
  const [isins, setIsins] = useState<any[]>([]);
  const [showAllIsins, setShowAllIsins] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (companyDetails.registeredAt && typeof companyDetails.registeredAt === 'object' && companyDetails.registeredAt.id) {
        try {
          const data = await fetchRegistrationAuthority(companyDetails.registeredAt.id);
          setRegistrationAuthority(data.data.attributes);
        } catch (error) {
          console.error('Error fetching registration authority:', error);
        }
      }

      if (companyDetails.registration && companyDetails.registration.validatedAt) {
        try {
          const data = await fetchValidationAuthority(companyDetails.registration.validatedAt.id);
          setValidationAuthority(data.data.attributes);
        } catch (error) {
          console.error('Error fetching validation authority:', error);
        }
      }

      if (companyDetails.legalForm && companyDetails.legalForm.id) {
        try {
          const legalFormData = await fetchEntityLegalForm(companyDetails.legalForm.id);
          setLegalFormNames(legalFormData.data.attributes.names);
        } catch (error) {
          console.error('Error fetching legal form:', error);
        }
      }

      if (companyDetails.registration && companyDetails.registration.managingLou) {
        try {
          const entityData = await fetchManagingLou(companyDetails.registration.managingLou);
          setManagingLouName(entityData.data.attributes.entity.legalName.name);
        } catch (error) {
          console.error('Error fetching managing LOU legal entity:', error);
        }
      }

      if (companyDetails.relationships?.directParent?.links?.["lei-record"]) {
        try {
          const directParentData = await fetchLeiData(companyDetails.relationships.directParent.links["lei-record"]);
          setDirectParent(directParentData.data);
        } catch (error) {
          console.error('Error fetching direct parent:', error);
        }
      }
  
      if (companyDetails.relationships?.ultimateParent?.links?.["lei-record"]) {
        try {
          const ultimateParentData = await fetchLeiData(companyDetails.relationships.ultimateParent.links["lei-record"]);
          setUltimateParent(ultimateParentData.data);
        } catch (error) {
          console.error('Error fetching ultimate parent:', error);
        }
      }

      if (companyDetails.relationships?.isins?.links?.related) {
        try {
          const isinsData = await fetchIsins(companyDetails.relationships.isins.links.related);
          setIsins(isinsData.data);
        } catch (error) {
          console.error('Error fetching ISINs:', error);
        }
      }
    };

    fetchData();
  }, [companyDetails]);

  // console.log('Tab1 companyDetails:', companyDetails); 

  const {
    goldenCopy,
    // lei,
    legalName,
    // otherNames,
    legalAddress,
    headquartersAddress,
    // registeredAt,
    // registeredAs,
    // jurisdiction,
    // category,
    // legalForm,
    // associatedEntity,
    status,
    // expiration,
    // successorEntity,
    // successorEntities,
    // creationDate,
    // subCategory,
    // otherAddresses,
    // eventGroups,
    registration,
    // bic,
    // mic,
    // ocid,
    // spglobal,
    conformityFlag,
  } = companyDetails;

  const legalAddressCountryName = countries.getName(legalAddress?.country as string, "en");
  const headquartersAddressCountryName = countries.getName(headquartersAddress?.country as string, "en");
  const legalAddressFlagEmoji = getFlagEmoji(legalAddress?.country as string);
  const headquartersAddressFlagEmoji = getFlagEmoji(headquartersAddress?.country as string);
  const [directParent, setDirectParent] = useState<any>(null);
  const [ultimateParent, setUltimateParent] = useState<any>(null);

  const toggleIsins = () => {
    setShowAllIsins(!showAllIsins);
  };

  return (
    <>
      <div className='px-4'>
        <h3 className='mt-8 mb-2 text-2xl font-bold'>{legalName?.name}</h3>
        <p className='tooltip' data-tooltip='Legal Entity registration status according to Global LEI index'>{status === 'ACTIVE' ? (
              <span className="text-green-500 font-bold">✅ Active Entity</span>
            ) : (
              <span className="text-red-500 font-bold">🔴 {status}</span>)}
        </p>
        <p className='tooltip' data-tooltip="The Policy Conformity Flag indicates whether the LEI record meets certain Regulatory Oversight Committee (ROC) policies.">
          {conformityFlag === 'CONFORMING' ? (
            <span className="text-green-500 font-bold" >✅ ROC Policy Conforming</span>
          ) : conformityFlag === 'NON_CONFORMING' ? (
            <span className="text-red-500 font-bold">🔴 ROC Policy Non Conforming</span>
          ) : (
            <span className="font-bold">{conformityFlag}</span>
          )}
        </p>
        <p className='tooltip' data-tooltip='LEI code status'>
          {registration.status === 'ISSUED' ? (
            <span className="text-green-500 font-bold" >✅ LEI Issued</span>
          ) : registration.status === 'LAPSED' ? (
            <span className="text-red-500 font-bold">🔴 LEI Lapsed</span>
          ) : (
            <span className="text-red-900 font-bold">{registration.status}</span>
          )}
        </p>
        <p className='tooltip' data-tooltip='Date and time of the last index update'>as of {goldenCopy?.publishDate ? goldenCopy.publishDate.slice(0,19).replace("T"," ") : ''}</p>
      </div>
      <div className='grid gap-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 px-4 py-4'>
        <Tab1GeneralInformation companyDetails={companyDetails} legalFormNames={legalFormNames} />
        <Tab1Addresses companyDetails={companyDetails} legalAddressCountryName={legalAddressCountryName} headquartersAddressCountryName={headquartersAddressCountryName} legalAddressFlagEmoji={legalAddressFlagEmoji} headquartersAddressFlagEmoji={headquartersAddressFlagEmoji}/>
        <Tab1LeiRegistrationDetails companyDetails={companyDetails} managingLouName={managingLouName} validationAuthority={validationAuthority}/>
        <Tab1OtherInformation companyDetails={companyDetails} registrationAuthority={registrationAuthority} isins={isins} showAllIsins={showAllIsins} toggleIsins={toggleIsins}/>
        <Tab1Parents companyDetails={companyDetails} directParent={directParent} ultimateParent={ultimateParent}/>
      </div>
    </>
  );
};

export default Tab1;
