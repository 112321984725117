import { CompanyDetails } from "../../../pages/LeiPage/CompanyDetails";

interface Tab1OtherInformationProps {
    companyDetails: CompanyDetails;
    registrationAuthority: any;
    isins: any;
    showAllIsins: any;
    toggleIsins: any;
}

const Tab1OtherInformation: React.FC<Tab1OtherInformationProps> = ({ companyDetails, registrationAuthority, isins, showAllIsins, toggleIsins }) => {
    const { ocid, spglobal, bic, mic, subCategory, otherAddresses, legalName, lei  } = companyDetails; 

    return (
        <div>
          {/* Other Information */}
          <h3 className="whitespace-nowrap mt-4 mb-2 text-xl font-bold">Other Information</h3>
          <table>
            <tbody>
              {ocid &&
                <tr className='hover:bg-btn-background'>
                  <td className='whitespace-nowrap font-bold align-top pr-2'>OpenCorporates ID</td><td className='align-top'><a href={`https://opencorporates.com/companies/${ocid}`} target='_blank' title={`Profile of ${legalName.name} on Opencorporates`} rel="noopener noreferrer">{ocid}</a></td>
                </tr>
              }
              {spglobal && spglobal.length > 0 && 
                <tr className='hover:bg-btn-background'>
                  <td className='whitespace-nowrap font-bold align-top pr-2'>S&P Global ID</td><td className='align-top'>{spglobal?.join(', ') ?? 'N/A'}</td>
                </tr>
              }
              {bic && 
                <tr className='hover:bg-btn-background'>
                  <td className='whitespace-nowrap font-bold align-top pr-2'>BIC</td><td className='align-top'>{bic?.join(', ') ?? 'N/A'}</td>
                </tr>
              }
              {mic && 
                <tr className='hover:bg-btn-background'>
                  <td className='whitespace-nowrap font-bold align-top pr-2'>MIC</td><td className='align-top'>{mic ?? 'N/A'}</td>
                </tr>
              }
              {registrationAuthority && (
                <tr>
                  <td className='whitespace-nowrap font-bold align-top pr-2'>Registered At</td>
                  <td className='align-top break-words'>
                    <div className='tooltip' data-tooltip='International Organization Name'>
                      {[
                        registrationAuthority?.internationalName, 
                        registrationAuthority?.internationalOrganizationName
                      ].filter(Boolean).join(', ')}
                    </div>
                    <div className='tooltip' data-tooltip='Local Organization Name'>
                      {[
                        registrationAuthority?.localName, 
                        registrationAuthority?.localOrganizationName
                      ].filter(Boolean).join(', ')}
                    </div>
                    <a
                      href={
                        registrationAuthority?.website?.startsWith("http")
                          ? registrationAuthority.website
                          : `https://${registrationAuthority?.website}`
                      }
                      target="_blank"
                      title={registrationAuthority?.internationalOrganizationName}
                      rel="noopener noreferrer"
                    >
                      Registration Authority's Website
                    </a>
                    <div className='tooltip' data-tooltip='Registration Authority Code'>
                      {registrationAuthority?.code}
                    </div>
                    {registrationAuthority?.localName && (
                      <ul>
                        {registrationAuthority?.jurisdictions.map((jurisdiction: any, index: number) => (
                          <li key={index}>
                            {jurisdiction.country && jurisdiction.country}&nbsp;
                            {jurisdiction.countryCode && `(${jurisdiction.countryCode})`}&nbsp;
                            {jurisdiction.jurisdiction && `- ${jurisdiction.jurisdiction}`}
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>
                </tr>
              )}
              {subCategory &&
                <tr>
                  <td className='whitespace-nowrap font-bold align-top pr-2'>Subcategory</td><td className='align-top'>{subCategory}</td>
                </tr>
              }
              {otherAddresses && otherAddresses.length > 0 && 
                <tr>
                  <td className='whitespace-nowrap font-bold align-top pr-2'>S&P Global Company ID</td><td className='align-top'>{spglobal?.join(', ') ?? 'N/A'}</td>
                </tr>}
              {companyDetails.links.self &&
                <tr>
                  <td className='whitespace-nowrap font-bold align-top pr-2'>External links</td>
                  <td className='align-top'>
                    <a href={`https://search.gleif.org/#/record/${lei}`} target="_blank" title={`Link to this Legal Entity ${legalName.name} on GLEIF website`} rel="noopener noreferrer">Link to GLEIF</a><br />
                    <a href={`https://app.leipapa.com/lei-details/${lei}`} target="_blank" title={`Link to this Legal Entity ${legalName.name} on LEIpapa Web App`} rel="noopener noreferrer">Link to LEIpapa</a><br />
                    <a href={companyDetails.links.self} target="_blank" title={`View data for ${legalName.name} in JSON`} rel="noopener noreferrer">Link to JSON data</a>

                  </td>
                </tr>
              }
              {isins.length > 0 && (
                <tr>
                  <td className='whitespace-nowrap font-bold align-top pr-2'>ISINs</td>
                  <td className='align-top'>
                    <ul>
                      {(showAllIsins ? isins : isins.slice(0, 3)).map((isin : any, index : any) => (
                        <li key={index}>{isin.attributes.isin}</li>
                      ))}
                    </ul>
                    {isins.length > 3 && (
                      <button onClick={toggleIsins} className="link-color">
                        {showAllIsins ? 'Show Less' : 'Show More'}
                      </button>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
    );
};

export default Tab1OtherInformation;