import { CompanyDetails } from "../../../pages/LeiPage/CompanyDetails";

interface Tab1AddressesProps {
    companyDetails: CompanyDetails;
    legalAddressCountryName: any;
    headquartersAddressCountryName: any;
    legalAddressFlagEmoji: any;
    headquartersAddressFlagEmoji: any
}

const Tab1Addresses: React.FC<Tab1AddressesProps> = ({ companyDetails, legalAddressCountryName, headquartersAddressCountryName, legalAddressFlagEmoji, headquartersAddressFlagEmoji }) => {
    const { legalAddress, headquartersAddress } = companyDetails;

    return (
        <>
          {/* Legal Address */}
          <div>
            <h3 className="mt-4 mb-2 text-xl font-bold">Legal Address</h3>
            <p className='tooltip' data-tooltip='Street Address'>{legalAddress?.addressLines.join(', ')}</p>
            {legalAddress?.city && ( <p className='tooltip' data-tooltip='Legal Address City'>{legalAddress?.city}</p> )}
            {legalAddress?.region && ( <p className='tooltip' data-tooltip='Legal Address Region'>{legalAddress?.region}</p> ) }
            {legalAddress?.country && ( <p className='tooltip' data-tooltip='Legal Address Country'>{legalAddress?.country} | {legalAddressCountryName} {legalAddressFlagEmoji}</p> ) }
            {legalAddress?.postalCode && ( <p className='tooltip' data-tooltip='Legal Address Postal Code'>{legalAddress?.postalCode}</p> ) }
            {legalAddress && (
              <ul>
                <li className='tooltip' data-tooltip='View Entity Legal Address on Google Maps'><a target='_blank' rel='noopener noreferrer' title='View Entity Legal Address on Google Maps' href={`https://www.google.com/maps/dir/?api=1&destination=${legalAddress?.addressLines.join(', ')}+${legalAddress?.city}+${legalAddress?.postalCode}+${legalAddressCountryName}`}>View on Google Maps</a></li>
                <li className='tooltip' data-tooltip='View Entity Legal Address on Bing Maps'><a target='_blank' rel='noopener noreferrer' title='View Entity Legal Address on Bing Maps' href={`https://www.bing.com/maps?where1=${legalAddress?.addressLines.join(', ')}+${legalAddress?.city}+${legalAddress?.postalCode}+${legalAddressCountryName}`}>View on Bing Maps</a></li>
              </ul>
            )}
          </div>

          {/* HQ Address */}
          <div>
            <h3 className="mt-4 mb-2 text-xl font-bold">Headquarters Address</h3>
            <p className='tooltip' data-tooltip='HQ Street Address'>{headquartersAddress?.addressLines.join(', ')}</p>
            {headquartersAddress?.city && ( <p className='tooltip' data-tooltip='HQ Address City'>{headquartersAddress?.city}</p> )}
            {headquartersAddress?.region && ( <p className='tooltip' data-tooltip='HQ Address Region'>{headquartersAddress?.region}</p> ) }
            {headquartersAddress?.country && ( <p className='tooltip' data-tooltip='Headquarters Address Country'>{headquartersAddress?.country} | {headquartersAddressCountryName} {headquartersAddressFlagEmoji}</p> ) }
            {headquartersAddress?.postalCode && ( <p className='tooltip' data-tooltip='HQ Address Postal Code'>{headquartersAddress?.postalCode}</p> ) }
            {headquartersAddress && (
              <ul>
                <li className='tooltip' data-tooltip='View Entity Headquarters Address on Google Maps'><a target='_blank' rel='noopener noreferrer' title='View Entity Hedquarters Address on Google Maps' href={`https://www.google.com/maps/dir/?api=1&destination=${headquartersAddress?.addressLines.join(', ')}+${headquartersAddress?.city}+${headquartersAddress?.postalCode}+${headquartersAddressCountryName}`}>View on Google Maps</a></li>
                <li className='tooltip' data-tooltip='View Entity Headquarters Address on Bing Maps'><a target='_blank' rel='noopener noreferrer' title='View Entity Headquarters Address on Bing Maps' href={`https://www.bing.com/maps?where1=${headquartersAddress?.addressLines.join(', ')}+${headquartersAddress?.city}+${headquartersAddress?.postalCode}+${headquartersAddressCountryName}`}>View on Bing Maps</a></li>
              </ul>
            )}
          </div>
        </>
    );
};   

export default Tab1Addresses;