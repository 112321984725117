import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { getFlagEmoji } from '../../utils/flagEmoji';
import { fetchLeiRecordDetails, fetchLeiRecordByLei, fetchLeiRecordByName, fetchFuzzyCompletions, fetchAutoCompletions } from '../../utils/api'

interface Suggestion {
  lei: string;
  entity: string;
  country: string;
}

const SearchTop: React.FC = () => {
  const [query, setQuery] = useState<string>('');
  const [autoCompletions, setAutoCompletions] = useState<Suggestion[]>([]);
  const [fuzzyCompletions, setFuzzyCompletions] = useState<Suggestion[]>([]);
  const navigate = useNavigate();
  const wrapperRef = useRef<HTMLFormElement>(null);

  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  useEffect(() => {
    setInitialLoading(false);
  }, []);

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (query.length < 3) {
      alert('Please enter at least 3 characters.');
      return;
    }
    navigate(`/results?query=${query}`);
  };

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuery(value);

    if (value.length >= 3) {
      try {
        // Fetch autocompletions
        const autoCompletionResponse = await fetchAutoCompletions(value);
        const autoCompletionRecords = await Promise.all(
          autoCompletionResponse.data.map(async (item: any) => {
            const lei = item.relationships?.['lei-records']?.data?.id || '';
            const entity = item.attributes.value;

            if (lei) {
              const leiRecordUrl = item.relationships['lei-records'].links.related;
              const leiDetails = await fetchLeiRecordDetails(leiRecordUrl);
              return {
                lei,
                entity: leiDetails.entity || entity,
                country: leiDetails.country,
              };
            }

            return {
              lei,
              entity,
              country: '',
            };
          })
        );

        const uniqueAutoCompletionRecords = Array.from(new Map(autoCompletionRecords.filter(record => record.lei).map(record => [record.lei, record])).values());
        setAutoCompletions(uniqueAutoCompletionRecords);

        const fuzzyCompletionResponse = await fetchFuzzyCompletions(value);
        const records = await Promise.all(
          fuzzyCompletionResponse.data.map(async (item: any) => {
            const lei = item.relationships?.['lei-records']?.data?.id || '';
            const entity = item.attributes.value;

            if (lei) {
              const leiRecordUrl = item.relationships['lei-records'].links.related;
              const leiDetails = await fetchLeiRecordDetails(leiRecordUrl);
              return {
                lei,
                entity: leiDetails.entity || entity,
                country: leiDetails.country,
              };
            }

            return {
              lei,
              entity,
              country: '',
            };
          })
        );

        let uniqueFuzzyRecords = Array.from(new Map(records.filter(record => record.lei).map(record => [record.lei, record])).values());

        if (uniqueFuzzyRecords.length === 0) {
          const leiResponse = await fetchLeiRecordByLei(value);

          if (leiResponse.data.length > 0) {
            uniqueFuzzyRecords = leiResponse.data.map((record: any) => ({
              lei: record.attributes.lei,
              entity: record.attributes.entity.legalName.name,
              country: record.attributes.entity.legalAddress.country,
            }));
          } else {
            const nameResponse = await fetchLeiRecordByName(value);

            uniqueFuzzyRecords = nameResponse.data.map((record: any) => ({
              lei: record.attributes.lei,
              entity: record.attributes.entity.legalName.name,
              country: record.attributes.entity.legalAddress.country,
            }));
          }
        }

        setFuzzyCompletions(uniqueFuzzyRecords);
      } catch (error) {
        console.error('Error fetching LEI data:', error);
        setAutoCompletions([]);
        setFuzzyCompletions([]);
      }
    } else {
      setAutoCompletions([]);
      setFuzzyCompletions([]);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setAutoCompletions([]);
      setFuzzyCompletions([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <form onSubmit={handleSearch} className="relative flex flex-col w-full py-0" ref={wrapperRef}>
        {initialLoading ? (
          <div className='text-center'>Get ready for Search 🤓</div>
        ) : (
          <div className="bar">
            <input
              type="text"
              className="text-foreground w-full h-10 border-0 outline-none bg-background"
              placeholder="LEI or company name..."
              value={query}
              onChange={handleInputChange}
            />
            <button id="search" title="Search" type="submit" className="search-icon">
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        )}

        {(autoCompletions.length > 0 || fuzzyCompletions.length > 0) && (
          <ul className="suggestions absolute bg-background border border-gray-300 w-full rounded-md shadow-lg">
            {autoCompletions.map((suggestion, index) => (
              <li
                className="flex py-2 px-6 cursor-pointer hover:bg-btn-background-hover"
                title={suggestion.entity}
                key={index}
                onClick={() => {
                  setAutoCompletions([]);
                  setFuzzyCompletions([]);
                  navigate(`/lei/${suggestion.lei}`);
                }}
              >
                <div className="suggestion-item">
                  <div className="flex items-center w-8 ">
                    {suggestion.country && <span>{getFlagEmoji(suggestion.country)}</span>}
                  </div>
                  <div className="flex flex-col">
                    <div className="font-bold">
                      {suggestion.entity} | {suggestion.country}
                    </div>
                    <div className="text-gray-500">({suggestion.lei})</div>
                  </div>
                </div>
              </li>
            ))}
            {fuzzyCompletions.length > 0 && (
              <li className="flex py-2 px-6">
                <div className="suggestion-item">
                  <div className="flex flex-col">
                    <div className="font-bold">
                      or did you mean...
                    </div>
                  </div>
                </div>
              </li>
            )}
            {fuzzyCompletions.map((suggestion, index) => (
              <li
                className="flex py-2 px-6 cursor-pointer hover:bg-btn-background-hover"
                title={suggestion.entity}
                key={index}
                onClick={() => {
                  setAutoCompletions([]);
                  setFuzzyCompletions([]);
                  navigate(`/lei/${suggestion.lei}`);
                }}
              >
                <div className="suggestion-item">
                  <div className="flex items-center w-8 ">
                    {suggestion.country && <span>{getFlagEmoji(suggestion.country)}</span>}
                  </div>
                  <div className="flex flex-col">
                    <div className="font-bold">
                      {suggestion.entity} | {suggestion.country}
                    </div>
                    <div className="text-gray-500">({suggestion.lei})</div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </form>
      <style>{`
        .bar {
          margin: 0 auto;
          width: 95%;
          max-width: 575px;
          border-radius: 20px;
          border: 1px solid #dcdcdc;
          padding: 0 20px;

          display: flex;
          align-items: center;
          position: relative;
        }
        .bar:hover {
          box-shadow: 1px 1px 8px 1px #dcdcdc;
        }
        .bar:focus-within {
          box-shadow: 1px 1px 8px 1px #dcdcdc;
          outline: none;
        }
        .suggestions {
          list-style-type: none;
          padding: 0;
          margin: 0 0 0 0;
          z-index: 5000;
          position: absolute;
          top: 50px;
          left: 15%;
          width: 100%;
        }
        .suggestion-item {
          display: grid;
          grid-template-columns: auto 1fr;
          grid-gap: 10px;
          align-items: center;
        }
        .search-icon {
          background: none;
          border: none;
          cursor: pointer;
          position: absolute;
          right: 15px;
          color: #888;
        }
        .search-icon:focus {
          outline: none;
        }
        @media (max-width: 768px) {
          .bar {
            width: 90%;
          }
          .suggestions {
            width: 95vw;
            left: -52px;
          }
          .suggestion-item {
            grid-template-columns: auto 1fr;
            grid-gap: 5px;
          }
        }
      `}</style>
    </>
  );
};

export default SearchTop;