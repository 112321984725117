import React from 'react';
import { useLocation } from 'react-router-dom';
import MainButton from "../Buttons/MainButton";
import SearchTop from '../SearchBars/_SearchTop';

const Header: React.FC = () => {
    const location = useLocation();
    const showSearch = !['/'].includes(location.pathname) && !location.pathname.startsWith('/results');

    return (
        <div className="flex-1 w-full flex flex-col gap-20 items-center">
            <nav className="top-gradient w-full flex justify-center border-b border-b-foreground/10 h-16">
                <div className="w-full max-w-4xl flex justify-between items-center p-3 text-sm">
                    <MainButton />
                    {showSearch && <SearchTop />}
                </div>
            </nav>
        </div>
    );
}

export default Header;