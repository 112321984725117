// src/components/Layout/Layout.tsx
import React, { useEffect } from 'react';
import Meta from './Meta';
import Header from './Header';
import Footer from './Footer';

interface LayoutProps {
  children: React.ReactNode;
  title?: string;
  description?: string;
  canonicalUrl?: string;
}

const Layout: React.FC<LayoutProps> = ({ children, title, description, canonicalUrl }) => {
  useEffect(() => {
    // Set document title
    if (title) {
      document.title = title;
    }

    // Set meta description
    if (description) {
      let metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute("content", description);
      } else {
        metaDescription = document.createElement('meta');
        metaDescription.setAttribute("name", "description");
        metaDescription.setAttribute("content", description);
        document.head.appendChild(metaDescription);
      }
    }

    // Set canonical link
    if (canonicalUrl) {
      let linkCanonical = document.querySelector('link[rel="canonical"]');
      if (linkCanonical) {
        linkCanonical.setAttribute("href", canonicalUrl);
      } else {
        linkCanonical = document.createElement('link');
        linkCanonical.setAttribute("rel", "canonical");
        linkCanonical.setAttribute("href", canonicalUrl);
        document.head.appendChild(linkCanonical);
      }
    }
  }, [title, description, canonicalUrl]);

  return (
    <div className="flex flex-col min-h-screen">
      {/* Header, Main content and Footer */}
      <header>
        <Meta />
        <Header />
      </header>
      <main className="flex-grow w-full">
        {children}
      </main>
      <footer className="w-full border-t border-t-foreground/10 p-8 flex flex-col justify-center text-center text-xs mt-auto">
        <Footer />
      </footer>
    </div>
  );
};

export default Layout;